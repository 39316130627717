import React from 'react';

const Terms = () => {
    return (
        <div id="terms">
            <h2>TERMS</h2>

            <section>
                <h3>Payment and Invoicing:</h3>
                <ul>
                    <li>Payment is due immediately upon rendering of services. Invoices are net due upon receipt, with labor payments via payroll service due net-15.</li>
                    <li>Full payment must be received within 30 days to avoid a late fee. Failure to pay promptly will result in a late fee, which cannot be waived.</li>
                </ul>
            </section>

            <section>
                <h3>Contracting and Labor Regulations:</h3>
                <ul>
                    <li>Payroll is disallowed for productions less than 10 consecutive days. Jaime Osvaldo is not subject to CA-AB5 provisions and is classified as an independent contractor in all cases.</li>
                </ul>
            </section>

            <section>
                <h3>Discounts and Special Circumstances:</h3>
                <ul>
                    <li>Discounts may be available for payments made within 72 hours from project completion.</li>
                    <li>For projects associated with the music industry or international productions, payment is required upon delivery. No exceptions to this policy.</li>
                </ul>
            </section>

            <section>
                <h3>Late Fees and Cancellation:</h3>
                <ul>
                    <li>Overdue invoices are subject to a 30% late fee per month. Late fees and collection costs are the client’s responsibility before copyright release.</li>
                    <li>Cancellation fees: 50% of the total contract value if cancelled within 72 hours of the start; 100% if within 48 hours. This includes equipment rental and other contracted services.</li>
                </ul>
            </section>

            <section>
                <h3>Travel and Turnaround Time:</h3>
                <ul>
                    <li>All travel expenses (visas, carnets, airfare, hotel, parking, etc.) are borne by the production. Preferred hotels should be 3-star or above; Wyndham properties are to be avoided. Spirit, Frontier, and Southwest airlines are not preferred; JetBlue is favored.</li>
                    <li>Turnaround for consecutive days is 10 hours off-clock. For travel jobs, hotel room door to hotel room door is considered portal-to-portal. If forced call is less than 10 hours off, labor rate moves to the next tier until a full 10-hour rest is received. Double time rate applies after 12 hours. The 8th day consecutive (or more) continues at the 7th day rate until 56 continuous hours off duty.</li>
                </ul>
            </section>

            <section>
                <h3>Meals and Penalties:</h3>
                <ul>
                    <li>After 5.99 hours of work, a 30-minute hot meal provided by production is on duty time. If a meal break is not provided after 6 hours, a meal penalty of $75 (10% of the daily rate) is charged for every 15 minutes delayed. The second meal post-wrap is also on-duty time for turnaround purposes.</li>
                </ul>
            </section>
            <section>
                <h3>Copyright and Contractual Agreements:</h3>
                <ul>
                    <li>Jaime Osvaldo holds mechanical copyright for all audio recordings created with his equipment and stored in any format until the invoice is fully paid, including late fees. Upon full payment, copyright reverts to the client.</li>
                    <li>This rate card and any invoices are the binding terms between Jaime Osvaldo and the client. No documents may alter these terms once work has begun. Any client documents must be signed by both parties before work to be effective. Booking constitutes acceptance of all terms.</li>
                </ul>
            </section>

            <section>
                <h3>Non-Disclosure Agreement (NDA):</h3>
                <ul>
                    <li>Jaime Osvaldo agrees to NDAs, provided they do not restrict self-promotion using the client's name 6 months post-project. Restrictions must be clearly defined in the NDA.</li>
                </ul>
            </section>

            <section>
                <h3>Production Scale and Scope:</h3>
                <ul>
                    <li>Project negotiations are based on good faith statements by the producer/production. Substantial discrepancies in scale or scope, including undisclosed hazardous situations, warrant immediate renegotiation.</li>
                </ul>
            </section>

            <section>
                <h3>Rental and Insurance:</h3>
                <ul>
                    <li>A rental agreement must be accepted prior to equipment use. Jaime Osvaldo requires a comprehensive insurance certificate from production, listing him as Additional Insured and Loss Payee for rented equipment. The production is responsible for maintaining this insurance, including workers' compensation for all on-location personnel. In case of loss and damage (L&D), the production assumes full responsibility for any lost or damaged items, including payment of insurance deductibles and repair or replacement costs at current retail rates or MSRP. Equipment remains payable at the daily rate until replaced or returned to service.</li>
                </ul>
            </section>

            <section>
                <h3>Weather and Hazardous Conditions:</h3>
                <ul>
                    <li>Jaime Osvaldo reserves the right to suspend services in unsafe weather or hazardous conditions. Decisions regarding continuation or rescheduling due to weather will be made 24 hours prior to call time, prioritizing safety.</li>
                </ul>
            </section>

            <section>
                <h3>Advance Booking and Payment Methods:</h3>
                <ul>
                    <li>To secure services, a non-refundable booking fee of 25% of the estimated total cost is required. This fee is credited against the final invoice. Accepted payment methods include Zelle, Direct Deposit, and Cash. Full payment as per the agreed terms is required to avoid late fees or service interruptions.</li>
                </ul>
            </section>

            <section>
                <h3>Jurisdiction:</h3>
                <ul>
                    <li>This agreement and any related disputes or claims are governed by New York State law. Both parties agree to the exclusive jurisdiction of New York courts for any disputes or claims related to this agreement.</li>
                </ul>
            </section>
            <hr id="policy"></hr>
            <section id="policy-section">
    <h3>Privacy Policy</h3>
    <p>Effective Date: November 2023</p>
        
    <h4>1. Introduction</h4>
    <p>Welcome to Jaime Osvaldo's official website. We respect your privacy and are committed to protecting your personal data. This privacy policy explains how we handle information collected through the contact form on our website.</p>

    <h4>2. Information Collection and Use</h4>
    <ul>
        <li><strong>What We Collect:</strong> The only personal information we collect is what you voluntarily provide in our contact form, such as your name, email address, and any other details you choose to provide.</li>
        <li><strong>How We Use It:</strong> Your information is used solely for the purpose of responding to your inquiries or requests related to Jaime Osvaldo's sound engineering services in film and audiovisual work.</li>
    </ul>

    <h4>3. Data Sharing and Disclosure</h4>
    <p>We do not share or disclose your personal information with third parties, except as required by law.</p>

    <h4>4. Data Security</h4>
    <p>We implement appropriate measures to protect your personal information from unauthorized access, use, or disclosure.</p>

    <h4>5. Your Rights</h4>
    <p>You have the right to access, correct, or request deletion of your personal information. Please contact us if you wish to exercise these rights.</p>

    <h4>6. Changes to This Policy</h4>
    <p>We may update this policy from time to time. We encourage you to review it periodically.</p>

    <h4>7. Contact Us</h4>
    <p>If you have any questions about this privacy policy, please contact us at support@jaimeosvaldo.com.</p>
</section>

        </div>
    );
};

export default Terms;

            